import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import ScrollToTop from "../../ScrollToTop";
import Footer from "../../Footer";

function OperationsManagement() {
    return (
        <>
            <Helmet>
                <title>Operations Management</title>
            </Helmet>
            <ScrollToTop />

            <section className="serviceDetail">
                <h2 className="wow fadeInUp">Operations Management</h2>
                <Container>
                    <Row className="align-items-center">
                        <Col lg="12" md="12" sm="12">
                            <div className="wow fadeInUp">
                                <img
                                    src="images/servicesVectors/OperationsManagement.png"
                                    alt="Operations Management"
                                    className="mainimg"
                                />
                            </div>
                            <div className="wow fadeInUp">

                            <p>
                            Efficient operations are the cornerstone of a successful business.
                            </p>
                            <p>
                            At Extronnect, we understand the importance of streamlining workflows, optimizing processes, and maximizing productivity. Our Operations Management service is designed to help businesses of all sizes improve efficiency, reduce costs, and enhance overall performance.
                            </p>
                            <p>
                            From inventory management and supply chain optimization to process improvement and workflow automation, our experienced operations managers leverage industry best practices and cutting-edge tools to identify inefficiencies, streamline operations, and drive sustainable growth. We work closely with your team to understand your unique business needs and develop customized solutions that align with your strategic objectives.
                            </p>
                            <p>
                            Our Operations Management service, provide you with valuable insights into your business operations, identify areas for improvement, and implement targeted strategies to drive results. Whether you're looking to reduce lead times, optimize inventory levels, or improve customer satisfaction, our team is here to help you achieve your operational goals and unlock new opportunities for success.
                            </p>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </section>
            <Footer />

        </>
    );
}

export default OperationsManagement;
