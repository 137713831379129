import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyC8Zw-RzQlIfNwm_Cru8ZOIL3NYef1Wc-4",
  authDomain: "extronnect-3cf1d.firebaseapp.com",
  projectId: "extronnect-3cf1d",
  storageBucket: "extronnect-3cf1d.appspot.com",
  messagingSenderId: "716363954025",
  appId: "1:716363954025:web:569b25dcfe3faff37b6ff8",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
export { db, storage };
