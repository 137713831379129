import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import ScrollToTop from "../../ScrollToTop";
import Footer from "../../Footer";

function EmailMarketing() {
    return (
        <>
            <Helmet>
                <title>Email Marketing</title>
            </Helmet>
            <ScrollToTop />

            <section className="serviceDetail">
                <h2 className="wow fadeInUp">Email Marketing</h2>
                <Container>
                    <Row className="align-items-center">
                        <Col lg="12" md="12" sm="12">
                            <div className="wow fadeInUp">
                                <img
                                    src="images/servicesVectors/emailMarketing.png"
                                    alt="email Marketing"
                                    className="mainimg"
                                />
                            </div>
                            <div className="wow fadeInUp">
                                <p>
                                    Nurturing Leads and Driving Conversions
                                </p>
                                <p>
                                    Email marketing remains one of the most effective channels for
                                    nurturing leads, engaging customers, and driving conversions.
                                    At Extronnect, our Email Marketing service is designed to help
                                    businesses leverage the power of email to communicate with
                                    their audience, deliver valuable content, and drive action.
                                </p>
                                <p>
                                    Our Email Marketing approach is grounded in strategy, creativity,
                                    and data-driven insights. We work closely with you to develop
                                    personalized email campaigns that resonate with your audience and
                                    align with your business goals. From lead nurturing to customer
                                    retention and reactivation, our team of email marketing experts
                                    crafts compelling email content, designs eye-catching templates,
                                    and deploys campaigns that deliver results.
                                </p>
                                <h3>
                                    Key elements of our Email Marketing service include:
                                </h3>
                                <p>
                                    <h5> Email Campaign Strategy:  </h5> We develop comprehensive email
                                    marketing strategies tailored to your business objectives, target
                                    audience, and industry landscape, ensuring maximum impact
                                    and ROI.
                                </p>
                                <p>
                                    <h5>  Content Creation:  </h5> Our team of experienced copywriters and
                                    designers creates engaging email content that educates, entertains,
                                    and inspires your audience, driving opens, clicks, and conversions.
                                </p>
                                <p>
                                    <h5>  Automation and Personalization:  </h5> We utilize advanced email
                                    marketing automation tools to deliver personalized, relevant content
                                    to your audience at the right time, increasing engagement and
                                    driving conversions.
                                </p>
                                <p>
                                    <h5>  List Management and Segmentation:  </h5> We manage and segment
                                    your email lists based on factors such as demographics, behavior,
                                    and interests, allowing for targeted and relevant communication
                                    that resonates with your audience.
                                </p>
                                <p>
                                    <h5>  Analytics and Reporting: </h5> We track key email metrics such as open
                                    rates, click-through rates, and conversions, providing actionable
                                    insights to optimize future campaigns and maximize ROI.
                                </p>
                                <p>
                                    Extronnect will harness the power of email marketing and drive
                                    engagement, conversions, and revenue for your business.
                                </p>
                            </div>

                        </Col>

                    </Row>
                </Container>
            </section>
            <Footer />
        </>
    );
}

export default EmailMarketing;
