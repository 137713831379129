import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import ScrollToTop from "../ScrollToTop";
import { Link } from "react-router-dom";
import Footer from "../Footer";

function CustomerSupport() {
  return (
    <>
    <div className="business">
         <Helmet>
        <title>Customer Support | Exceptional Customer Service Solutions </title>
      </Helmet>
      <ScrollToTop /> 

      <section className="homesec3" id="About">
        <h2  className="wow fadeInUp" >Customer Support Services</h2>
        <Container>
          <Row className="align-items-center">
            <Col lg="6" md="6" sm="12">
              <div className="sec3imgdiv wow fadeInLeft" >
                <img
                  src="images/vectors/customersupport.png"
                  alt="customer support"
                  className="mainimg"
                />
              </div>
            </Col>
            <Col lg="6" md="6" sm="12">
              <div className="wow fadeInUp" >
                <h3>
                  With a focus on delivering exceptional service, our customer
                  support team acts as an extension of your brand, fostering
                  positive relationships and enhancing customer satisfaction.
                </h3>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="homesec2">
        <Container>
          <h2  className=" wow fadeInUp" >Services Offered</h2>
          <Row>
            <Col lg="4" md="4" sm="12">
              <div>
                <div className=" wow fadeInUp" >

         
                <Link to="/CustomerSupportRepresentatives">

                  <div className="hoverdetail">
                    <img
                      src="images/services/op1/CustomerSupport.png"
                      alt="Customer Support"
                    />

                    <h3>
                      Customer Support
                      <br /> Representatives
                    </h3>
                  </div>
                  </Link>

                </div>
              </div>
            </Col>

            <Col lg="4" md="4" sm="12">
              <div>
                <div className=" wow fadeInUp" >
                <Link to="/TeamManagement">

                  <div className="hoverdetail">
                    <img
                      src="images/services/op1/part4/TeamManagement.png"
                      alt="Team Management"
                    />

                    <h3>
                      Team <br />
                      Management
                    </h3>
                  </div>
                  </Link>

                </div>
              </div>
            </Col>

            <Col lg="4" md="4" sm="12">
              <div>
                <div className=" wow fadeInUp" >
                <Link to="/TrainingDevelopmentS">

                  <div className="hoverdetail">
                    <img
                      src="images/services/op1/part4/Training&Development.png"
                      alt="Training & Development"
                    />

                    <h3>
                      Training &<br /> Development
                    </h3>
                  </div>
                  </Link>

                </div>
              </div>
            </Col>

            <Col lg="4" md="4" sm="12">
              <div>
                <div className=" wow fadeInUp" >
                <Link to="/CustomerSuccesManagementS">

                  <div className="hoverdetail">
                    <img
                      src="images/services/op1/part4/CustomerSuccessManagement.png"
                      alt="Customer Success Management"
                    />

                    <h3>
                      Customer Success <br />
                      Management
                    </h3>
                  </div>
                  </Link>

                </div>
              </div>
            </Col>
            <Col lg="4" md="4" sm="12">
              <div>
                <div className=" wow fadeInUp" >
                <Link to="/QualityControlS">

                  <div className="hoverdetail">
                    <img
                      src="images/services/op1/part4/QualityControl.png"
                      alt="Quality Control"
                    />

                    <h3>
                      Quality
                      <br />
                      Control
                    </h3>
                  </div>
                  </Link>

                </div>
              </div>
            </Col>
            <Col lg="4" md="4" sm="12">
              <div>
                <div className=" wow fadeInUp" >
                <Link to="/RealTimeAnalysts">

                  <div className="hoverdetail">
                    <img
                      src="images/services/op1/part4/RealTimeAnalysts.png"
                      alt="Real Time Analysts (RTA)"
                    />

                    <h3>
                      Real Time Analysis
                      <br /> (RTA)
                    </h3>
                  </div>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
          <p  className=" wow fadeInUp">
            At Extronnect, we understand the importance of delivering
            exceptional customer support to cultivate long-term relationships
            and drive business growth. Our customer support services encompass a
            wide range of channels, including phone, email, and live chat,
            ensuring that your customers receive prompt and personalised
            assistance whenever they need it. Whether it's resolving inquiries,
            addressing concerns, or providing product recommendations, our
            dedicated team is committed to delivering a seamless and satisfying
            experience for your customers.
          </p>
        </Container>
      </section>
    </div>
    <Footer />
    </>
  );
}

export default CustomerSupport;
