import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import ScrollToTop from "../../ScrollToTop";
import Footer from "../../Footer";

function QualityControl() {
    return (
        <>
            <Helmet>
                <title>Quality Control</title>
            </Helmet>
            <ScrollToTop />

            <section className="serviceDetail">
                <h2 className="wow fadeInUp">Quality Control</h2>
                <Container>
                    <Row className="align-items-center">
                        <Col lg="12" md="12" sm="12">
                            <div className="wow fadeInUp">
                                <img
                                    src="images/servicesVectors/QualityControl.png"
                                    alt="QualityControl"
                                    className="mainimg"
                                />
                            </div>
                            <div className="wow fadeInUp">

                            <p>
                                Quality control is essential for maintaining high standards of product and service excellence, enhancing customer satisfaction, and driving business success.
                            </p>
                            <p>
                                At Extronnect, our Quality Control service is dedicated to ensuring that your products, services, and processes meet the highest standards of quality and consistency.

                            </p>
                            <p>
                                Our team of quality specialists employs a rigorous and systematic approach to monitor, evaluate, and improve quality at every stage of the production or service delivery process. From conducting inspections and audits to implementing corrective actions and continuous improvement initiatives, we work tirelessly to identify and resolve quality issues before they impact your business.
                            </p>
                            <p>
                                By collaborating with Extronnect, you can demonstrate your commitment to excellence and build customer trust. Our comprehensive quality solutions help you identify opportunities
                                for improvement, minimize defects and errors, and drive continuous improvement across your organization, ensuring that you deliver superior products and services that exceed customer expectations.
                            </p>
                            <p>
                                Our services are designed to provide comprehensive support including but not limited to business functions. From administrative tasks such as data entry and document management to more specialized roles like customer service
                                and project coordination, Our hires are equipped to handle diverse responsibilities.
                            </p>
                            <p>
                                By outsourcing these tasks to Extronnect, With extensive experience and expertise, you can free up valuable time and resources, we will handle diverse requirements, providing efficient and reliable support to optimize your workflow.
                            </p>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </section>
            <Footer />

        </>
    );
}

export default QualityControl;
