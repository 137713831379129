import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import ScrollToTop from "../../ScrollToTop";
import Footer from "../../Footer";

function EditingVideos() {
    return (
        <>
            <Helmet>
                <title>Editing Videos</title>
            </Helmet>
            <ScrollToTop />

            <section className="serviceDetail">
                <h2 className="wow fadeInUp">Editing Videos</h2>
                <Container>
                    <Row className="align-items-center">
                        <Col lg="12" md="12" sm="12">
                            <div className="wow fadeInUp">
                                <img
                                    src="images/servicesVectors/EditingVideos.png"
                                    alt="Editing Videos"
                                    className="mainimg"
                                />
                            </div>
                            <p className="wow fadeInUp">
                                Video content dominates the social media landscape, and your
                                adeptness at video editing elevates storytelling to new heights.
                                From the initial conceptualization to the final cut, your meticulous
                                approach ensures seamless, engaging, and impactful videos.
                                Whether it's a short promotional clip or a longer-form narrative,
                                your editing skills bring a dynamic edge to the visual narratives,
                                captivating audiences and leaving a lasting impression.
                            </p>

                        </Col>

                    </Row>
                </Container>
            </section>
            <Footer />

        </>
    );
}

export default EditingVideos;
