import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import ScrollToTop from "../../ScrollToTop";
import Footer from "../../Footer";

function CustomerSuccesManagement() {
    return (
        <>
            <Helmet>
                <title>Customer Succes Management</title>
            </Helmet>
            <ScrollToTop />

            <section className="serviceDetail">
                <h2 className="wow fadeInUp">Customer Succes <br />Management</h2>
                <Container>
                    <Row className="align-items-center">
                        <Col lg="12" md="12" sm="12">
                            <div className="wow fadeInUp">
                                <img
                                    src="images/servicesVectors/CustomerSuccesManagement.png"
                                    alt="Customer Succes Management"
                                    className="mainimg"
                                />
                            </div>
                            <div className="wow fadeInUp">

                            <p>
                            Our Customer Success Management service goes beyond traditional customer support—it's about forming meaningful partnerships with our clients and empowering them to achieve their goals. From the moment you’re on board with Extronnect, you gain a dedicated team of client success managers who are committed to understanding your unique challenges, objectives, and aspirations.
                            </p>
                            <p>
                            We work hand in hand with you to develop personalized strategies and action plans tailored to your specific needs. Whether it's optimizing workflows, resolving issues, or seizing new opportunities, our team is there to provide guidance, support, and insights to help you navigate the complexities of your business landscape.
                            </p>
                            <p>
                            What sets us apart is our proactive approach to client success. We don't just wait for problems to arise; we anticipate them and take proactive steps to address them before they impact your business. With regular check-ins, weekly goal reviews, and on-demand meetings, we ensure that you're always on track and equipped with the resources you need to succeed.
                            </p>
                            <p>
                            Our commitment to your success extends beyond mere satisfaction—we measure our success by your achievements. By partnering with Extronnect for your client success needs, you can trust that you're in capable hands. Together, we'll embark on a journey toward growth, innovation, and unparalleled success.                            </p>
                       </div>
                        </Col>

                    </Row>
                </Container>
            </section>
            <Footer />

        </>
    );
}

export default CustomerSuccesManagement;
