import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import ScrollToTop from "../../ScrollToTop";
import Footer from "../../Footer";

function VisualIdentity() {
  return (
    <>
      <Helmet>
        <title>Visual Identity</title>
      </Helmet>
      <ScrollToTop />

      <section className="serviceDetail">
        <h2 className="wow fadeInUp">Visual Identity</h2>
        <Container>
          <Row className="align-items-center">
            <Col lg="12" md="12" sm="12">
              <div className="wow fadeInUp">
                <img
                  src="images/servicesVectors/VisualIdentity.png"
                  alt="Visual Identity"
                  className="mainimg"
                />
              </div>
              <p className="wow fadeInUp">
                At the heart of brand communication lies the visual identity,
                and here is where your expertise truly shines. You intricately
                weave together colors, typography, and imagery to construct a
                distinctive visual language for your clients. Whether it's
                creating or revamping logos, establishing consistent design
                elements, or conceptualizing brand guidelines, your focus is on
                forging a visual identity that resonates with the essence of
                each brand you touch.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
}

export default VisualIdentity;
