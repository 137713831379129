import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';

function ScrollToHash() {
    const location = useLocation();

    useEffect(() => {
        const { hash } = location;
        if (hash) {
            const element = document.getElementById(hash.slice(1));
            if (hash === "#Inquiry") {
                if (window.innerWidth < 960) {
                    scroll.scrollTo(7500);
                } else {
                    scroll.scrollTo(4500);

                }
            }
            else if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    return null;
}
export default ScrollToHash;