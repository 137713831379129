import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import ScrollToTop from "../../ScrollToTop";
import { FaCircle } from "react-icons/fa";
import Footer from "../../Footer";

function AcquisitionClosers() {
  return (
    <>
      <Helmet>
        <title>Acquisition Closers</title>
      </Helmet>
      <ScrollToTop />

      <section className="serviceDetail">
        <h2 className="wow fadeInUp">Acquisition Closers</h2>
        <Container>
          <Row className="align-items-center">
            <Col lg="12" md="12" sm="12">
              <div className="wow fadeInUp">
                <img
                  src="images/servicesVectors/acquisitionclosers.png"
                  alt="acquisition closers"
                  className="mainimg"
                />
              </div>
              <div className="wow fadeInUp">
                <p>Strategic Negotiation for Success</p>
                <p>
                  Closing deals is both an art and a science and at Extronnect,
                  we excel in the craft of strategic negotiation. Our
                  acquisition closers are skilled in navigating complex
                  transactions, overcoming objections, and securing favorable
                  outcomes for your business.
                </p>
                <p>
                  With years of experience and a deep understanding of
                  negotiation tactics, our team works tirelessly to ensure every
                  deal is closed with precision and efficiency. Whether it's
                  negotiating contracts, finalizing agreements, or securing
                  partnerships, we approach each transaction with a strategic
                  mindset and a focus on delivering value for your business.
                </p>
                <h3>
                  When you partner with Extronnect for acquisition closing, You
                  can expect:
                </h3>
                <p>
                  {" "}
                  <FaCircle /> Expert negotiation strategies tailored to your
                  unique objectives.
                </p>
                <p>
                  <FaCircle /> Diligent contract review and management.
                </p>
                <p>
                  <FaCircle /> Seamless coordination with all stakeholders
                  involved in the deal.
                </p>
                <p>
                  <FaCircle /> Timely and efficient deal closure, minimizing
                  time-to-revenue.
                </p>
                <p>
                  <FaCircle /> Ongoing support and guidance throughout the
                  negotiation process.
                </p>
                <p>
                  Trust Extronnect to be your strategic ally in closing deals
                  and achieving your business objectives with confidence.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
}

export default AcquisitionClosers;
