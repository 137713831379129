import React, { useState, useEffect } from "react";
import { Form, Button, Container, Alert } from "react-bootstrap";
import Footer from "./Footer";
import { db } from "./firebase";
import { getDoc, collection, doc, addDoc } from "firebase/firestore";

const MessageNewsLetter = () => {
  const [message, setMessage] = useState(" ");
  const [subject, setSubject] = useState(" ");

  const [showAlert, setShowAlert] = useState(false);
  const [emailNumber, setEmailNumber] = useState(0);

  useEffect(() => {
    const fetchEmailNumber = async () => {
      try {
        const emailDoc = await getDoc(doc(db, "newsLetter", "emails"));
        const emailData = emailDoc.data();
        const emailNumber = Object.keys(emailData).length;
        setEmailNumber(emailNumber);
      } catch (error) {
        // console.error("Error fetching email data:", error);
      }
    };

    fetchEmailNumber();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const emailDoc = await getDoc(doc(db, "newsLetter", "emails"));
      const emailData = emailDoc.data();
      Object.keys(emailData).forEach((fieldId) => {
        const shouldSend = emailData[fieldId];
        if (shouldSend) {
          try {
            const emailHtml = message;
            const signatureHtml = `<font color="#888888">
            <span class="gmail_signature_prefix">-- </span><br>
            <div dir="ltr" class="gmail_signature" data-smartmail="gmail_signature">
                <div dir="ltr">
                    <table class="signature-table" width="414" cellpadding="0" cellspacing="0" border="0">
                        <tbody>
                            <tr>
                                <td valign="top" class="signature-cell">
                                    <table border="0" cellpadding="0" cellspacing="0" width="414" class="inner-table">
                                        <tbody>
                                            <tr>
                                                <td class="no-padding">
                                                    <span class="name-title"><font face="arial black, sans-serif">Samir H</font></span>
                                                </td>
                                                <td class="no-padding"></td>
                                            </tr>
                                            <tr>
                                                <td class="no-padding">
                                                    <span class="position-title"><font face="arial black, sans-serif">Client Success Manager<br><br></font></span>
                                                </td>
                                                <td align="right" width="170" class="no-padding">
                                                    <p dir="ltr" class="social-links">
                                                        <span>
                                                            <a href="http://extronnect.com/" target="_blank">
                                                                <img src="https://lh7-us.googleusercontent.com/ERQye9fHlkGU5k3qeAOaIPlXt6WEXXaU3Qk6sX-RPc989aGEznY7hiAxlQrZZy4jbAFXGrMQpBM7XooSYiCJQoTXjmwx2feUNpI2IMZAFp4rcsQCUmyJTDeeYB7wXVKx2ajwEOROluc4SDSAovfxy44" width="23" height="23" alt="Website" class="icon">
                                                            </a>&nbsp;
                                                        </span>
                                                        <span>
                                                            <a href="https://www.instagram.com/extronnect/" target="_blank">
                                                                <img src="https://lh7-us.googleusercontent.com/RBHXzA6EarsjkC28LnCKIOX46gSouRWQvToQulLKq_T8amnmvJz3GO1Pd5GML1bps41dLRSlJe8y3UR6olRNRWBBzflLsAPtb2KI9n6PynABhc6WXrH3CrSimE6b-7cn4ntkAr6EnTkr2i0Qw9QQr0w" width="23" height="23" alt="Instagram" class="icon">
                                                            </a>&nbsp;
                                                        </span>
                                                        <span>
                                                            <a href="https://wa.me/15619225376" target="_blank">
                                                                <img src="https://lh7-us.googleusercontent.com/Rgf8cTDfganYaSmVeRG8an1XXfBmeubjqi1eCPjKsprqPPgN6A-isjGF-TvzNNacha4ut_sMzFYXo3DbOEMb9SIcp4vA1kKnvykcTww8iV_wh2L6GsyVb9aTQiZO5e7m-2hESYGqdtbnOFF8WJBWuUg" width="23" height="23" alt="WhatsApp" class="icon">
                                                            </a>&nbsp;
                                                        </span>
                                                        <span>
                                                            <a href="https://www.linkedin.com/company/100955777" target="_blank">
                                                                <img src="https://lh7-us.googleusercontent.com/0554XXBDxwmR3G1JA7ijlqWufEnXfK_l1LUpuqg-eydQVjai3t0GXbVp1xrqfvKH1g1thL1EzqjUpMqvwITbTb7ndmCv1125NqDuBN45wsCQkCB17bxXIpAEfbCMv_G2WQwpAqFp6vIBmNo3uqgzKMI" width="23" height="23" alt="LinkedIn" class="icon">
                                                            </a>
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="no-padding">
                                                    <span class="contact-info">
                                                        <span class="icon-cell">
                                                            <img src="https://lh7-us.googleusercontent.com/qv5uavYFMPAfRw6bWUk8GYOV-ngLGdu4ndYfyql8dq8Cogw2Jfgm4IZhbUIyj50D0kNLCA-J8mF_YCkw5RrKm-6p3cQog0MTiVkR6WE_EK5fcMgLP9ufvtzS_N87-tNRUJuucuG9sNvShAplf9oDnW4" width="19" height="19" class="contact-icon">
                                                        </span>
                                                        <font face="arial black, sans-serif">(310) 601-8347</font>
                                                        <br>
                                                        <span class="icon-cell">
                                                            <img src="https://lh7-us.googleusercontent.com/d6sEan4jectEq-6hIecRokXTA4nHSmFDVk0v71K5EXwxoepvfnMyxoNfUhrrTlVOLbssr81WHwRaBO-IhvFHOrD2vkl0gsB7nmxPznFc_YGxFTRiwwvhQMgxlr4i3mPJ2gz9SSNdsbZFLQMRs0JZzQo" width="19" height="19" class="contact-icon">
                                                        </span>&nbsp;
                                                        <a href="mailto:support@extronnect.com" class="email-link">Support@extronnect.com</a>&nbsp; &nbsp; &nbsp;
                                                    </span>
                                                </td>
                                                <td valign="bottom" align="right" class="no-padding"></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td class="banner-cell">
                                    <a href="https://extronnect.com/" target="_blank">
                                        <img border="0" alt="Banner" width="414" src="https://ci3.googleusercontent.com/meips/ADKq_Nao5PJGW-cu5EO2By_8Ke9Dcl1rL90Pfc7dZ7nzBIH9qx9rk_HYSLsB2oqY6rLLWq_UIJ-n=s0-d-e1-ft#https://i.imgur.com/u495xLU.png" class="banner-image">
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td class="disclaimer-cell">
                                    The content of this email is confidential and intended for the recipient specified in message only. It is strictly forbidden to share any part of this message with any third party, without a written consent of the sender. If you received this message by mistake, please reply to this message and follow with its deletion, so that we can ensure such a mistake does not occur in the future.
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </font>
       `; 
            const fullHtml = emailHtml + signatureHtml;
            try {
              addDoc(collection(db, "mail"), {
                to: fieldId,
                message: {
                  subject: subject,
                  html: fullHtml,
                },
              });
              //   console.log("Email added to Firestore for:", fieldId);
            } catch (error) {
              //   console.error(
              //     "Error adding email to Firestore for",
              //     fieldId,
              //     error
              //   );
            }
            setMessage("");
            setSubject("");
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), 3000);
            // console.log("Email sent successfully to:", fieldId);
          } catch (error) {
            // console.error("Error sending email to", fieldId, error);
          }
        }
      });
    } catch (error) {
      //   console.error("Error fetching email addresses:", error);
    }
  };

  return (
    <>
      <Container style={{ marginTop: "200px", marginBottom: "200px" }}>
        <h1>Welcome to the Message NewsLetter Page ({emailNumber} Users)</h1>
        {showAlert && (
          <Alert
            variant="success"
            onClose={() => setShowAlert(false)}
            dismissible
          >
            Message sent successfully!
          </Alert>
        )}
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="Subject" style={{ marginBottom: "20px" }}>
            <Form.Label>Type your Subject:</Form.Label>
            <Form.Control
              type="text"
              placeholder="The Subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="message" style={{ marginBottom: "20px" }}>
            <Form.Label>Type your message:</Form.Label>
            <Form.Control
              as="textarea"
              placeholder="The Message"
              rows={3}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            />
          </Form.Group>
          <Button
            variant="warning"
            type="submit"
            style={{ backgroundColor: "#ffc81d", marginBottom: "20px" }}
          >
            Send
          </Button>
        </Form>
      </Container>
      <Footer />
    </>
  );
};

export default MessageNewsLetter;
