import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import ScrollToTop from "../ScrollToTop";
import { Link } from "react-router-dom";
import Footer from "../Footer";

function Marketing() {
  return (
    <>
      <div className="business">
        <Helmet>
          <title>Digital Marketing Services | Driving Success with Powerful Campaigns  </title>
        </Helmet>
        <ScrollToTop />

        <section className="homesec3 ">
          <h2>Digital Marketing Services</h2>
          <Container>
            <Row className="align-items-center">
              <Col lg="6" md="6" sm="12">
                <div className="sec3imgdiv wow fadeInLeft" data-wow-duration="1s">
                  <img
                    src="images/vectors/marketing.png"
                    alt="marketing"
                    className="mainimg"
                  />
                </div>
              </Col>
              <Col lg="6" md="6" sm="12">
                <div className="wow fadeInUp" data-wow-duration="1s">
                  <h3>
                    Propel your brand forward with our innovative marketing
                    strategies designed to captivate your audience and drive
                    results. From SMS and email campaigns to comprehensive
                    marketing plans, we craft compelling messages that resonate
                    with your target market. Let us be your guide in navigating
                    the ever-evolving landscape of marketing to achieve your
                    business objectives.
                  </h3>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="homesec2">
          <Container>
            <h2>Services Offered</h2>
            <Row>
              <Col lg="4" md="4" sm="12">
                <div>
                  <div className=" wow fadeInUp" data-wow-duration="1s">
                    <Link to="/StrategyBuilding">
                      <div className="hoverdetail">
                        <img
                          src="images/services/op1/part2/Marketingcmapaigns.png"
                          alt="Marketing cmapaigns"
                        />

                        <h3>
                          Strategy <br />
                          Building
                        </h3>
                      </div>
                    </Link>

                  </div>
                </div>
              </Col>

              <Col lg="4" md="4" sm="12">
                <div>
                  <div className=" wow fadeInUp" data-wow-duration="1s">
                    <Link to="/BrandMarketing">

                      <div className="hoverdetail">
                        <img
                          src="images/services/op1/part5/brandmarketing.png"
                          alt="brand marketing"
                        />

                        <h3>
                          Brand <br />
                          Marketing
                        </h3>
                      </div>
                    </Link>
                  </div>
                </div>
              </Col>

              <Col lg="4" md="4" sm="12">
                <div>
                  <div className=" wow fadeInUp" data-wow-duration="1s">
                    <Link to="/ContentCreation">

                      <div className="hoverdetail">
                        <img
                          src="images/services/op1/part4/ContentCreation.png"
                          alt="Content Creation"
                        />

                        <h3>
                          Content <br />
                          Creation
                        </h3>
                      </div>
                    </Link>

                  </div>
                </div>
              </Col>

              <Col lg="4" md="4" sm="12">
                <div>
                  <div className=" wow fadeInUp" data-wow-duration="1s">
                    <Link to="/SocialMediaMarketing">

                      <div className="hoverdetail">
                        <img
                          src="images/services/op1/part4/SocialMediaMarketingModeration.png"
                          alt="Social Media Marketing & Moderation"
                        />

                        <h3>
                          Social Media Marketing & <br />
                          Moderation
                        </h3>
                      </div>
                    </Link>

                  </div>
                </div>
              </Col>

              <Col lg="4" md="4" sm="12">
                <div>
                  <div className=" wow fadeInUp" data-wow-duration="1s">
                    <Link to="/EmailMarketing">
                      <div className="hoverdetail">
                        <img
                          src="images/services/op1/part4/EmailMarketing.png"
                          alt="Email Marketing"
                        />

                        <h3>
                          Email <br />
                          Marketing
                        </h3>
                      </div>
                    </Link>
                  </div>
                </div>
              </Col>
              <Col lg="4" md="4" sm="12">
                <div>
                  <div className=" wow fadeInUp" data-wow-duration="1s">
                    <Link to="/SMSMarketing">

                      <div className="hoverdetail">
                        <img
                          src="images/services/op1/SMSMarketing.png"
                          alt="SMS Marketing"
                        />

                        <h3>
                          SMS <br />
                          Marketing
                        </h3>
                      </div>
                    </Link>

                  </div>
                </div>
              </Col>
            </Row>
            <p className=" wow fadeInUp">
              Extronnect offers a comprehensive suite of marketing services
              designed to help businesses reach their target audience, Starting
              from build brand awareness, Market Research.Provide a deep
              Competitor analysis along with a prospective SWOT analysis and drive
              customer engagement.
              <br />
              <br />
              From SMS and email marketing campaigns to general marketing
              strategies, our team of marketing experts utilises a combination of
              data-driven insights, creative content, and strategic planning to
              deliver impactful results. Whether you're looking to launch a new
              product, promote a special offer, or expand your market reach,
              Extronnect's marketing services can help you achieve your goals.
            </p>
          </Container>
        </section>
      </div>

      <Footer />
    </>
  );
}

export default Marketing;
