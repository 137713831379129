import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import ScrollToTop from "../../ScrollToTop";
import { FaCircle } from "react-icons/fa";
import Footer from "../../Footer";

function StrategyBuilding() {
    return (
        <>
            <Helmet>
                <title>Strategy Building</title>
            </Helmet>
            <ScrollToTop />

            <section className="serviceDetail">
                <h2 className="wow fadeInUp">Strategy Building</h2>
                <Container>
                    <Row className="align-items-center">
                        <Col lg="12" md="12" sm="12">
                            <div className="wow fadeInUp">
                                <img
                                    src="images/servicesVectors/DataManagement.png"
                                    alt="DataManagement"
                                    className="mainimg"
                                />
                            </div>
                            <div className="wow fadeInUp">

                                <p>
                                    Crafting Your Path to Success with Strategic Marketing Planning
                                </p>
                                <p>
                                    At Extronnect, we understand that a successful marketing strategy
                                    is the cornerstone of any business's growth and success. Our strategy
                                    -building service is dedicated to helping you define and implement
                                    a tailored marketing plan that aligns with your business objectives
                                    and resonates with your target audience. From market research and
                                    competitor analysis to goal setting and campaign planning, our team
                                    of strategic marketing experts works closely with you to develop
                                    a comprehensive strategy that drives results and achieves your
                                    business goals.
                                </p>
                                <h3>With Extronnect's marketing strategic services, You can expect:</h3>
                                <p>
                                    <FaCircle />     A thorough analysis of market trends, consumer behavior, and
                                    competitive landscape to identify opportunities and challenges
                                </p>
                                <p>
                                    <FaCircle />    Development of a clear and actionable marketing plan with
                                    defined objectives, target audience, messaging, and tactics
                                </p>
                                <p>
                                    <FaCircle />    Customized strategies for different marketing channels, including
                                    digital, social media, email, and traditional marketing
                                </p>
                                <p>
                                    <FaCircle />    Ongoing monitoring and optimization of marketing campaigns to
                                    maximize ROI and achieve continuous improvement
                                </p>
                                <p>
                                    <FaCircle />     Expert guidance and support from our team of marketing strategists
                                    to help you navigate the complexities of the marketing landscape
                                    and achieve your desired outcomes
                                </p>
                                <p>
                              Partner with Extronnect to develop a strategic marketing plan that
                                    sets your business up for long-term success and growth.
                                </p>

                            </div>
                        </Col>

                    </Row>
                </Container>
            </section>
            <Footer />
        </>
    );
}

export default StrategyBuilding;
