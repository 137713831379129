import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import { NavLink, useNavigate } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Navbar } from "react-bootstrap";
import { IoMdArrowDropdown } from "react-icons/io";
import { NavHashLink } from "react-router-hash-link";
import { animateScroll as scroll } from 'react-scroll';

function NavBar() {
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleCloseOffcanvas = () => {
    window.scrollTo(0, scrollPosition); // Restore scroll position
    setShowOffcanvas(false);
  };
  let navigate = useNavigate();

  const handleHashLinkClick = (event) => {
    if (window.innerWidth < 960) {

  
    event.preventDefault();
        navigate("/")
        const hash = event.target.getAttribute("href");
    const cleanedHash = hash.replace(/^\/#/, ''); // Remove leading slash and hash
    if (cleanedHash === "About") {
      scroll.scrollTo(2700); 


    }else if (cleanedHash === "Services") {
      scroll.scrollTo(1600); 


    }else if (cleanedHash === "Strategy") {
      scroll.scrollTo(3900); 


    }else if (cleanedHash === "Inquiry") {
      scroll.scrollTo(7500); 


    }else if (cleanedHash === "Clients") {
      scroll.scrollTo(6400); 


    }
 
      setTimeout(handleCloseOffcanvas, 500); // Adjust the timeout value if needed
  }
  };




  return (
    <Navbar expand="lg" className="bg-body-tertiary mb-3">
      <Container fluid className="d-flex justify-content-between align-items-baseline">
        <Navbar.Brand>
          <NavLink to="/" className="ImgNav">
            <img
              src="images/660876887abcb9a37db36343.gif"
              alt="logo"
              className="logoImg"
            />
          </NavLink>
        </Navbar.Brand>
        <Navbar.Toggle
          className="textttt"
          aria-controls="offcanvasNavbar"
          onClick={() => setShowOffcanvas(!showOffcanvas)}
        />
        <Navbar.Offcanvas
          show={showOffcanvas}
          onHide={handleCloseOffcanvas}
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
          placement="end"
          className="justify-content-between"
        >
          <Offcanvas.Header closeButton>

          </Offcanvas.Header>
          <Offcanvas.Body className="textttt">
            <Nav className="navgroup align-items-center w-100 justify-content-between">
              <NavLink to="/" onClick={handleCloseOffcanvas}>
                Home
              </NavLink>
              <span className="dropdown">
                <NavHashLink smooth to="/#Services" className="dropbtn">
                  Services
                  <IoMdArrowDropdown />
                </NavHashLink>
                <div className="dropdown-content">
                  <NavLink to="/BusinessAdministration" onClick={handleCloseOffcanvas}>
                    Business Administration
                  </NavLink>
                  <NavLink to="/SoftwareDevelopment" onClick={handleCloseOffcanvas}>
                    Software Development
                  </NavLink>
                  <NavLink to="/CustomerSupport" onClick={handleCloseOffcanvas}>
                    Customer Support
                  </NavLink>
                  <NavLink to="/SalesSolutions" onClick={handleCloseOffcanvas}>
                  Automated Sales
                  </NavLink>
                  <NavLink to="/Marketing" onClick={handleCloseOffcanvas}>
                  Digital Marketing
                  </NavLink>
                  <NavLink to="/CreativeServices" onClick={handleCloseOffcanvas}>
                    Creative Services
                  </NavLink>
                </div>
              </span>
              <NavHashLink smooth to="/#About" onClick={handleHashLinkClick}>
                About Us
              </NavHashLink>
              <NavHashLink smooth to="/#Strategy" onClick={handleHashLinkClick}>
                Strategy Call
              </NavHashLink>
              <NavHashLink smooth to="/#Inquiry" onClick={handleHashLinkClick}>
                Submit an Inquiry
              </NavHashLink>

              <NavHashLink smooth to="/#Clients" onClick={handleHashLinkClick}>
                What Our Clients Say
              </NavHashLink>

            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}

export default NavBar;
