import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import ScrollToTop from "../../ScrollToTop";
import { FaCircle } from "react-icons/fa";
import Footer from "../../Footer";

function TeamManagement() {
    return (
        <>
            <Helmet>
                <title>Team Management</title>
            </Helmet>
            <ScrollToTop />

            <section className="serviceDetail">
                <h2 className="wow fadeInUp">Team Management</h2>
                <Container>
                    <Row className="align-items-center">
                        <Col lg="12" md="12" sm="12">
                            <div className="wow fadeInUp">
                                <img
                                    src="images/servicesVectors/TeamManagement.png"
                                    alt="TeamManagement"
                                    className="mainimg"
                                />
                            </div>
                            <div className="wow fadeInUp">
                                <p>
                                    Effective team management is essential for maximizing productivity, fostering collaboration, and achieving business objectives.

                                </p>
                                <p>
                                    At Extronnect, our Team Management service is designed to empower your teams with the tools, resources, and guidance they need to
                                    excel in their roles and drive results for your business.
                                </p>
                                <p>
                                    Our approach encompasses various aspects, including team building, performance management, goal setting, and conflict resolution.
                                    We work closely with your organization to understand your team dynamics and objectives, developing customized strategies and initiatives to optimize team performance and alignment with
                                    your business goals.
                                </p>
                                <p>
                                    With Extronnect’s Team Management services, You can expect:
                                </p>

                                <p>
                                  <FaCircle /> Strategic resource allocation to maximize productivity and efficiency
                                </p>
                                <p>
                                   <FaCircle />  Clear communication channels to foster collaboration and teamwork
                                </p>
                                <p>
                                <FaCircle />   Performance tracking and measurement to identify areas for improvement
                                </p>
                                <p>

                                <FaCircle />  Coaching and mentorship to develop team members' skills and capabilities
                                </p>
                                <p>
                                <FaCircle />  Conflict resolution and problem-solving to address issues and challenges proactively
                                </p>
                                <p>
                                  You can unlock the full potential of your teams and cultivate a culture of high performance and accountability within your organization. Our experienced managers provide hands-on support and guidance, ensuring that your teams have the structure, support, and motivation they need to succeed.
                                </p>

                            </div>
                        </Col>

                    </Row>
                </Container>
            </section>
            <Footer />
        </>
    );
}

export default TeamManagement;
