import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import ScrollToTop from "../../ScrollToTop";
import Footer from "../../Footer";

function ExecutiveAssistance() {
    return (
        <>
            <Helmet>
                <title>Executive Assistance</title>
            </Helmet>
            <ScrollToTop />

            <section className="serviceDetail">
                <h2 className="wow fadeInUp">Executive Assistance</h2>
                <Container>
                    <Row className="align-items-center">
                        <Col lg="12" md="12" sm="12">
                            <div className="wow fadeInUp">
                                <img
                                    src="images/servicesVectors/ExecutiveAssistance.png"
                                    alt="Executive Assistance"
                                    className="mainimg"
                                />
                            </div>
                            <div className="wow fadeInUp">

                            <p>
                                In today's fast-paced business landscape, effective leadership is
                                essential for driving growth and success.
                            </p>
                            <p>
                                At Extronnect, our Executive Assistance service is designed to
                                empower business leaders, executives, and entrepreneurs with
                                the support they need to excel in their roles. From managing
                                hectic schedules to handling complex administrative tasks,
                                our dedicated team of executive assistants ensures that nothing
                                falls through the cracks, allowing you to focus on strategic
                                decision-making and business development.
                            </p>
                            <p>
                                Our assistants are highly skilled professionals with extensive
                                experience providing top-tier support to C-suite executives
                                and business leaders across industries. Whether it's
                                coordinating meetings, organizing travel arrangements,
                                or drafting important documents, our team is committed
                                to delivering exceptional service with precision and
                                professionalism.
                            </p>
                            <p>
                                With us, you will gain access to a trusted ally who understands
                                your unique requirements and is dedicated to helping you
                                achieve your business objectives. With our support, you can
                                confidently navigate the demands of leadership, knowing that
                                your administrative tasks are in capable hands.
                            </p>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </section>
            <Footer />

        </>
    );
}

export default ExecutiveAssistance;
