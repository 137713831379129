import React from "react";
// import { ScheduleMeeting } from "react-schedule-meeting";
import { Container, Row, Col } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
import { Helmet } from 'react-helmet';
import ScrollToTop from "./ScrollToTop";
import Footer from "./Footer";
// import TimezoneSelect from 'react-timezone-select';
// import moment from 'moment-timezone';
// import { IoIosArrowRoundBack } from "react-icons/io";
// import { GoClock } from "react-icons/go";
// import { TbWorld } from "react-icons/tb";
// import { CiCalendar } from "react-icons/ci";

function ScheduleCall() {
  // const [showCalendar, setShowCalendar] = useState(true);
  // const [selectedTimezone, setSelectedTimezone] = useState('America/Dawson');
  // const [selectedDate, setSelectedDate] = useState(null);
  // const [selectedTime, setSelectedTime] = useState(null);

  // const handleBackButtonClick = () => {
  //   setShowCalendar(true);
  // };

  // const generateTimeSlots = (timezone) => {
  //   const availableTimeslots = [];
  //   const currentDate = moment.tz(moment(), timezone);
  //   const currentYear = currentDate.year();
  //   const nextYear = currentYear + 1;
  //   const currentMonth = currentDate.month();

  //   for (let year = currentYear; year <= nextYear; year++) {
  //     for (let month = 0; month < 12; month++) {
  //       if (year === currentYear && month < currentMonth) continue;
  //       if (year === nextYear && month > currentMonth) break;

  //       const daysInMonth = moment({ year, month }).daysInMonth();
  //       for (let day = 1; day <= daysInMonth; day++) {
  //         const date = moment.tz({ year, month, day }, timezone);
  //         const dayOfWeek = date.day();

  //         if (dayOfWeek === 0) continue;

  //         const startTime = date.clone().hour(16).minute(0).second(0);
  //         const endTime = date.clone().hour(23).minute(30).second(0);

  //         availableTimeslots.push({
  //           id: date.valueOf(),
  //           startTime: startTime.toDate(),
  //           endTime: endTime.toDate(),
  //         });
  //       }
  //     }
  //   }

  //   return availableTimeslots;
  // };

  // const handleTimeSelect = (startTime) => {
  //   console.log("Selected start time:", startTime);
  //   setSelectedTime(startTime);
  //   setShowCalendar(false);
  // };

  // const handleDaySelect = (day) => {
  //   console.log("Selected day:", day);
  //   setSelectedDate(day);
  // };

  return (
    <>
      <Helmet>
        <title>Extronnect | Book a Free Strategy Call - Tailored Consultation </title>
      </Helmet>
      <ScrollToTop /> 

      <section className="call">
        <Container>
          <Row>
            <Col lg="12" md="12" sm="12">
              <h2>
                Schedule a <span>FREE </span>strategy call!
              </h2>
              <p>
                "At Extronnect, we're committed to helping you achieve your
                business goals. Schedule a free strategy call with our experts
                to discuss how we can tailor our services to meet your unique
                needs."
              </p>

              <h3>Benefits:</h3>
              <ul>
                <li>
                  <FaCheck /> Personalized advice tailored to your business.
                </li>
                <li>
                  <FaCheck /> Insights into industry best practices and trends.
                </li>
                <li>
                  <FaCheck /> Customized solutions to address your specific
                  challenges.
                </li>
                <li>
                  <FaCheck /> Opportunity to explore how Extronnect can support
                  your growth journey.
                </li>
              </ul>
              <h5>
                "Ready to take your business to the next level?
                <br />
                Schedule your <span>FREE</span> strategy call now!"
              </h5>
            </Col>
          </Row>
          {/* <iframe  src="https://api.leadconnectorhq.com/widget/booking/dEymCnw4QkpM9nJJwlEd" id="dEymCnw4QkpM9nJJwlEd_1716179108457"></iframe> */}
          <iframe className="calenderr" title="calender" scrolling="no" src="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ3yziFfsiDKtZF2sOtIoYaFRn2VWLBvStAsaLvG0GIDkMa_VrgHJ3fBQj8k-K8RyM_D5Al0mp8s"  width="100%" frameborder="0"></iframe>
        </Container>
      </section>
       
      {/* {showCalendar && (
        <section className="calenderSec">
          <Container>
            <Row className="CallRow">
              <Col md="12" lg="4" sm="12">
                <div className="callDiv1">
                <img
                    src="images/05993dfa7132ab1ad50099b06767aa50_1200_80.webp"
                  />
                  <h3>Schedule a free strategy call</h3>
                  <p><GoClock /> 30 Mins</p>
                  <p>

                  <CiCalendar />


                  {selectedDate && <> {moment(selectedDate).format("MMMM Do YYYY")}</>}
                  {selectedTime && <> {moment(selectedTime).format("h:mm a")}</>}
                  </p>
                  <p><TbWorld /> {selectedTimezone}</p>
                </div>
              </Col>
              <Col md="12" lg="8" sm="12" className="selectcall">
                <p>Select Date & Time</p>
                <ScheduleMeeting
                  availableTimeslots={generateTimeSlots(selectedTimezone)}
                  backgroundColor="#097275"
                  borderRadius={50}
                  eventStartTimeSpreadInMinutes={15}
                  format_nextFutureStartTimeAvailableFormatString="cccc, LLLL do"
                  format_selectedDateDayTitleFormatString="cccc, LLLL do"
                  format_selectedDateMonthTitleFormatString="LLLL yyyy"
                  format_startTimeFormatString="h:mm a"
                  eventDurationInMinutes={15}
                  lang_cancelButtonText="Cancel"
                  lang_confirmButtonText="Select"
                  lang_emptyListText="No times available"
                  lang_goToNextAvailableDayText="Next Available"
                  lang_noFutureTimesText="No future times available"
                  onNoFutureTimesAvailable={(selectedDate) =>
                    console.log("No future times available for", selectedDate)
                  }
                  onSelectedDayChange={(day) =>
                    handleDaySelect(day)
                  }
                  onStartTimeSelect={(startTimeEventEmit) =>
                    handleTimeSelect(startTimeEventEmit)
                  }
                  primaryColor="#1a786f"
                  disablePast={true} // Disable past dates
                  multiple={false}
                />

                <div className="timezoneee">
                  <p>Time zone</p>
                  <TimezoneSelect
                    value={selectedTimezone}
                    onChange={(value) => setSelectedTimezone(value.value)}
                    className="timezones"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      )}
      {!showCalendar && (
        <section className="calenderSec">
          <Container>
            <Row className="CallRow">
              <Col md="3" lg="3" sm="12">
                <div className="callDiv1">
                  <button onClick={handleBackButtonClick} className="backBtn"><IoIosArrowRoundBack /></button>
                  <h3>Schedule a free strategy call</h3>
                  <p><GoClock /> 30 Mins</p>
                  <p>

                  <CiCalendar />


                  {selectedDate && <> {moment(selectedDate).format("MMMM Do YYYY")}</>}
                  {selectedTime && <> {moment(selectedTime).format("h:mm a")}</>}
                  </p>
                  <p><TbWorld /> {selectedTimezone}</p>
                </div>
              </Col>
              <Col md="9" lg="9" sm="12">
                <section className="schudle2">
                  <form>
                    <div className="formDiv">
                      <h3>Schedule a free strategy call</h3>
                      <div>
                        <div>
                          <div className="row justify-content-between">
                            <div className="col-md-6">
                              <p>First Name *</p>
                              <input
                                type="text"
                                name="name"
                                placeholder="First Name *"
                                className="form-control"
                              />
                            </div>
                            <div className="col-md-6">
                              <p>Last Email *</p>
                              <input
                                type="text"
                                name="l_name"
                                placeholder="Last Email *"
                                className="form-control"
                              />
                            </div>
                            <div className="col-md-6">
                              <p>Email *</p>
                              <input
                                type="email"
                                name="email"
                                placeholder="Email *"
                                className="form-control"
                              />
                            </div>
                            <div className="col-md-6">
                              <p>Phone *</p>
                              <input
                                type="tel"
                                name="phone"
                                placeholder="Phone *"
                                className="form-control"
                              />
                            </div>
                            <div className="col-md-12">
                              <p>What industry are you in? *</p>
                              <input
                                type="text"
                                name="content"
                                placeholder="content"
                                className="form-control"
                              />
                            </div>
                          </div>
                          <div>
                            <p>Inquiry Details *</p>
                            <input
                              type="text"
                              name="inquiry"
                              style={{ height: "60px" }}
                              className="inquiry form-control"
                            />
                          </div>
                          <div className="checkDiv">
                            <input type="checkbox" />
                            <label>
                              I agree to <span>terms & conditions</span> provided by the company. By providing my phone number, I agree to receive text messages from the business.
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button type="submit">Schedule Meeting</button>
                  </form>
                </section>
              </Col>
            </Row>
          </Container>
        </section>
      )} */}
      <Footer />
    </>
  );
}

export default ScheduleCall;
