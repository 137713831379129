import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import ScrollToTop from "../../ScrollToTop";
import Footer from "../../Footer";

function SMSMarketing() {
    return (
        <>
            <Helmet>
                <title>SMS Marketing</title>
            </Helmet>
            <ScrollToTop />

            <section className="serviceDetail">
                <h2 className="wow fadeInUp">SMS Marketing</h2>
                <Container>
                    <Row className="align-items-center">
                        <Col lg="12" md="12" sm="12">
                            <div className="wow fadeInUp">
                                <img
                                    src="images/servicesVectors/SMSMarketing.png"
                                    alt="SMSMarketing"
                                    className="mainimg"
                                />
                            </div>
                            <div className="wow fadeInUp">

                                <p>
                                    Engaging Your Audience with Instant Communication
                                </p>
                                <p>
                                    In today's fast-paced world, SMS marketing offers a direct and
                                    immediate way to engage with your audience, deliver timely
                                    messages, and drive action. At Extronnect, our SMS Marketing
                                    service is designed to help businesses connect with their audience
                                    on the devices they use most frequently, driving engagement and
                                    conversions.
                                </p>
                                <p>
                                    Our SMS Marketing approach is built on the principles of relevance,
                                    timeliness, and value. We work closely with you to develop targeted
                                    SMS campaigns that deliver personalized messages to your audience,
                                    driving opens, clicks, and conversions. From promotional offers to
                                    event reminders and transactional notifications, our team of SMS
                                    marketing experts crafts compelling messages that resonate with
                                    your audience and drive action.
                                </p>
                                <h3>Key elements of our SMS Marketing service include:</h3>
                                <p>
                                    <h5>
                                        Campaign Strategy and Planning:  </h5> We develop customized SMS
                                    marketing strategies tailored to your business goals, target audience,
                                    and industry landscape, ensuring maximum impact and ROI.
                                </p>
                                <p>
                                    <h5> Message Creation:  </h5> Our team of experienced copywriters crafts
                                    engaging SMS messages that grab attention, convey your brand
                                    voice, and drive action, whether it's making a purchase, signing up
                                    for an event, or visiting your website.
                                </p>
                                <p>
                                    <h5>  List Management and Segmentation:  </h5> We manage and segment
                                    your SMS subscriber list based on factors such as demographics,
                                    behavior, and interests, allowing for targeted and relevant
                                    communication that resonates with your audience.
                                </p>
                                <p>
                                    <h5>Compliance and Regulations:  </h5> We ensure compliance with SMS
                                    marketing regulations and best practices to protect your brand
                                    reputation and maintain trust with your audience.
                                </p>
                                <p>
                                    <h5>Analytics and Reporting: </h5> We track key SMS metrics such as delivery
                                    rates, open rates, and conversion rates, providing actionable insights
                                    to optimize future campaigns and maximize ROI.
                                </p>
                                <p>
                                    Extronnect is going to leverage the power of SMS marketing and
                                    drive instant engagement and growth for your business.
                                </p>
                            </div>

                        </Col>

                    </Row>
                </Container>
            </section>
            <Footer />

        </>
    );
}

export default SMSMarketing;
