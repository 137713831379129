import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import ScrollToTop from "../../ScrollToTop";
import Footer from "../../Footer";

function BrandMarketing() {
    return (
        <>
            <Helmet>
                <title>Brand Marketing</title>
            </Helmet>
            <ScrollToTop />

            <section className="serviceDetail">
                <h2 className="wow fadeInUp">Brand Marketing</h2>
                <Container>
                    <Row className="align-items-center">
                        <Col lg="12" md="12" sm="12">
                            <div className="wow fadeInUp">
                                <img
                                    src="images/servicesVectors/DataManagement.png"
                                    alt="Brand Marketing"
                                    className="mainimg"
                                />
                            </div>
                            <div className="wow fadeInUp">

                                <p>
                                    Building Your Brand Identity for Lasting Impact                                </p>
                                <p>
                                    In today's competitive marketplace, building a strong and memorable
                                    brand is essential for standing out from the crowd and attracting
                                    your target audience.
                                </p>
                                <p>
                                    At Extronnect, we understand the critical importance of brand identity.
                                    Our Brand Marketing service is designed to help businesses establish,
                                    elevate, and strengthen their brand presence through strategic
                                    initiatives that resonate with their target audience.
                                </p>
                                <p>
                                    Our approach to Brand Marketing begins with a comprehensive
                                    analysis of your business, industry, target audience, and competitors.
                                    We work closely with you to define your unique value proposition and
                                    develop a brand positioning strategy that sets you apart from the
                                    competition. From there, our team of experienced brand strategists
                                    and creatives collaborates to bring your brand to life through
                                    compelling messaging, visual branding elements, and creative
                                    concepts.
                                </p>
                                <h3>Key elements of our Brand Marketing service include:</h3>
                                <p>
                                    <h5>   Brand Strategy Development: </h5> We work with you to define your
                                    brand's identity, values, and personality, ensuring alignment with
                                    your business objectives and target audience.
                                </p>
                                <p>
                                    <h5>   Visual Branding:  </h5> Our team of designers creates visual branding
                                    elements such as logos, color palettes, typography, and imagery
                                    that reflect your brand identity and resonate with your audience.
                                </p>
                                <p>
                                    <h5>  Brand Messaging:  </h5> We craft compelling brand messaging that
                                    communicates your unique value proposition and resonates with
                                    your target audience across all marketing channels.
                                </p>
                                <p>
                                    <h5>   Brand Campaigns:  </h5> Our creative team develops integrated brand
                                    campaigns that leverage multiple channels and touchpoints to
                                    create meaningful connections with your audience and drive brand
                                    awareness and engagement.
                                </p>
                                <p>
                                    <h5>   Brand Management:  </h5> We provide ongoing brand management
                                    services to ensure consistency and coherence across all brand
                                    touchpoints and communications, helping you build brand loyalty
                                    and trust over time.
                                </p>
                                <p>
                                    Get with Extronnect to elevate your brand presence and create
                                    lasting impressions with your audience through strategic Brand
                                    Marketing initiatives.
                                </p>

                            </div>
                        </Col>

                    </Row>
                </Container>
            </section>
            <Footer />

        </>
    );
}

export default BrandMarketing;
