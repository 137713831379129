import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import ScrollToTop from "../../ScrollToTop";
import Footer from "../../Footer";

function PayrollManagement() {
    return (
        <>
            <Helmet>
                <title>Payroll Management</title>
            </Helmet>
            <ScrollToTop />

            <section className="serviceDetail">
                <h2 className="wow fadeInUp">Payroll Management</h2>
                <Container>
                    <Row className="align-items-center">
                        <Col lg="12" md="12" sm="12">
                            <div className="wow fadeInUp">
                                <img
                                    src="images/servicesVectors/PayrollManagement.png"
                                    alt="Payroll Management"
                                    className="mainimg"
                                />
                            </div>
                            <div className="wow fadeInUp">

                            <p>
                            Payroll management is a critical function for any business,
                            </p>
                            <p>
                            Ensuring that employees are paid accurately and on time while adhering to regulatory requirements. At Extronnect, our Payroll Management service is designed to streamline the payroll process, minimize errors, and ensure compliance with applicable laws and regulations.
                            </p>
                            <p>
                            Our team of payroll specialists combines expertise in payroll processing with meticulous attention to detail to handle all aspects of payroll administration. From calculating wages and deductions to managing tax filings and employee benefits, we take care of the complexities of payroll so you can focus on running your business.
                            </p>
                            <p>
                            With Extronnect for your payroll management needs, you can gain peace of mind knowing that your payroll is in capable hands. With our efficient and accurate payroll services, you can reduce administrative burden, improve payroll accuracy, and ensure compliance with all relevant regulations, allowing you to focus on what matters most—growing your business.
                            </p>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </section>
            <Footer />

        </>
    );
}

export default PayrollManagement;
