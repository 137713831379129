import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import ScrollToTop from "../../ScrollToTop";
import { FaCircle } from "react-icons/fa";
import Footer from "../../Footer";

function DataManagement() {
    return (
        <>
            <Helmet>
                <title>Data Management</title>
            </Helmet>
            <ScrollToTop />

            <section className="serviceDetail">
                <h2 className="wow fadeInUp">Data Management</h2>
                <Container>
                    <Row className="align-items-center">
                        <Col lg="12" md="12" sm="12">
                            <div className="wow fadeInUp">
                                <img
                                    src="images/servicesVectors/DataManagement.png"
                                    alt="Data Management"
                                    className="mainimg"
                                />
                            </div>
                            <div className="wow fadeInUp">

                                <p>
                                    Leverage Data for Informed Decision-Making
                                </p>
                                <p>
                                    In today's rapidly evolving business landscape, data is the lifeblood of
                                    success. At Extronnect, we recognize the critical importance of data
                                    management in driving informed decision-making and achieving
                                    business objectives. Our comprehensive data management solutions
                                    are designed to empower you with accurate, reliable, and actionable
                                    insights that drive growth and innovation.
                                </p>
                                <p>
                                    Our data management process begins with meticulous data collection,
                                    ensuring that we capture all relevant information needed to fuel your
                                    business intelligence initiatives. We then utilize advanced analytics
                                    tools and techniques to analyze and interpret the data, uncovering
                                    valuable insights and trends that inform strategic decision-making.
                                </p>
                                <h3>
                                    With Extronnect's data management services, You can expect:
                                </h3>
                                <p>
                                <FaCircle />    Robust data governance frameworks to ensure data quality,
                                    consistency, and security
                                </p>
                                <p>
                                <FaCircle />    Customized data models and algorithms tailored to your specific
                                    business needs
                                </p>
                                <p>
                                <FaCircle />    Advanced data visualization techniques to communicate insights
                                    effectively
                                </p>
                                <p>
                                <FaCircle />    Continuous monitoring and optimization to adapt to changing
                                    business requirements
                                </p>
                                <p>
                                <FaCircle />   Seamless integration with your existing systems and processes for
                                    maximum efficiency
                                </p>
                                <p>
                              Partner with Extronnect to unlock the full potential of your data and
                                    drive business success through informed decision-making.
                                </p>
                           
                            </div>

                        </Col>

                    </Row>
                </Container>
            </section>
            <Footer />

        </>
    );
}

export default DataManagement;
