import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import ScrollToTop from "../ScrollToTop";
import Footer from "../Footer";

function SoftwareDevelopment() {
  return (
    <>
    <div className="business">
         <Helmet>
        <title>Software Development | Tailored Digital Solutions Expertise </title>
      </Helmet>
      <ScrollToTop /> 

      <section className="homesec3" id="About">
        <h2 className=" wow fadeInUp">Software Development</h2>
        <Container>
          <Row className="align-items-center">
            <Col lg="6" md="6" sm="12">
              <div className="sec3imgdiv wow fadeInLeft" >
                <img
                  src="images/vectors/SoftwareDevelopmentService.png"
                  alt="Software Development Service"
                  className="mainimg"
                />
              </div>
            </Col>
            <Col lg="6" md="6" sm="12">
              <div className="wow fadeInRight" >
                <h3>
                  Transform your digital presence with Extronnect's cutting-edge
                  Software Development solutions. Our team of seasoned
                  developers specializes in crafting custom software tailored to
                  your brand's unique needs and vision. From intuitive user
                  interfaces to seamless backend systems, we leverage the latest
                  technologies to create robust, scalable, and high-performance
                  software solutions
                </h3>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="homesec2" id="Services">
        <Container>
          <h2>Services Offered</h2>
          <Row>
            <Col lg="3" md="3" sm="12">
              <div>
                <div className=" wow fadeInUp" >
                  <div className="hoverdetail">
                    <img
                      src="images/services/op1/part2/Websitecreation.png"
                      alt="Website creation"
                    />
                    <h3>Website Creation</h3>
                  </div>
                </div>
              </div>
            </Col>

            <Col lg="3" md="3" sm="12">
              <div>
                <div className=" wow fadeInUp" >
                  <div className="hoverdetail">
                    <img
                      src="images/services/op1/part2/CRMautomations.png"
                      alt="CRM set up & automations"
                    />
                    <h3>CRM/Dashboard creation</h3>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg="3" md="3" sm="12">
              <div>
                <div className=" wow fadeInUp" >
                  <div className="hoverdetail">
                    <img
                      src="images/services/op1/part4/APIWebhookService.png"
                      alt="API & Webhook Service"
                    />
                    <h3>
                      API & <br />
                      Webhook
                    </h3>
                  </div>
                </div>
              </div>
            </Col>

            <Col lg="3" md="3" sm="12">
              <div>
                <div className=" wow fadeInUp" >
                  <div className="hoverdetail">
                    <img
                      src="images/services/op1/part4/LeadFunnelAutomation.png"
                      alt="Lead Funnel Automation"
                    />
                    <h3>
                      Lead Funnel <br />
                      Automation
                    </h3>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="serviceSec">
        <Container>
          <Row>
            <Col md="8" lg="8"  className=" wow fadeInUp">
              <h3>AI Powered Automation Services</h3>
              <ul>
                <li>- Reports</li>
                <li>- Forms & Responses</li>
                <li>- Emails & newsletter & subscriptions</li>
                <li>- Dashboard reflections (must support API and Webhooks)</li>
              </ul>
            </Col>
            <Col md="4" lg="4"  className=" wow fadeInRight">
              <div className="imgDiv">
                <img
                  src="images/services/op1/part4/AIPoweredAutomationServices.png"
                  alt="AI Powered Automation Services"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="serviceSec differ">
        <Container>
          <Row>
            <Col md="4" lg="4"  className=" wow fadeInLeft">
              <div className="imgDiv">
                <img
                  src="images/services/op1/part4/AIPoweredBusinessSolutions.png"
                  alt="AI Powered Business Solutions"
                />
              </div>
            </Col>
            <Col md="8" lg="8"  className=" wow fadeInUp">
              <h3>AI Powered Business Solutions</h3>
              <ul>
                <li>
                  - Quality Assurance system for QAs to monitor any type of lead
                  process
                </li>
                <li>
                  - Business Specific Dashboard with custom graphs and charts
                </li>
                <li>
                  - Data storing, processing and calculation (must support API
                  and Webhooks)
                </li>
                <li>
                  - Automating social media ad campaigns and implementing AI
                  prompting to reply
                </li>
                <li>
                  - Auto messaging/replying system for Social Media can be
                  powered by AI
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
    <Footer />

    </>
  );
}

export default SoftwareDevelopment;
