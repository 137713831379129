import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import ScrollToTop from "../../ScrollToTop";
import Footer from "../../Footer";

function TrainingDevelopment() {
    return (
        <>
            <Helmet>
                <title>Training & Development</title>
            </Helmet>
            <ScrollToTop />

            <section className="serviceDetail">
                <h2 className="wow fadeInUp">Training & Development</h2>
                <Container>
                    <Row className="align-items-center">
                        <Col lg="12" md="12" sm="12">
                            <div className="wow fadeInUp">
                                <img
                                    src="images/servicesVectors/Training&Development.png"
                                    alt="Training&Development"
                                    className="mainimg"
                                />
                            </div>
                            <div className="wow fadeInUp">

                                <p>
                                    Investing in training and development is essential for building a skilled
                                    and motivated workforce that drives business success.</p>
                                <p> At Extronnect,
                                    our Training & Development service is dedicated to empowering
                                    your team members with the knowledge, skills, and capabilities
                                    they need to excel in their roles and contribute to your organization's
                                    growth and success.                            </p>
                                <p>

                                    Our comprehensive training programs cover a wide range of topics,
                                    from technical skills development to leadership training and soft
                                    skills enhancement. We work closely with your organization to
                                    understand your training needs and objectives, developing
                                    customized training solutions that address your specific challenges
                                    and opportunities. We utilize a variety of training methods,
                                    including workshops, seminars, online courses, and on-the-job
                                    training, to accommodate different learning styles and preferences.    </p>
                                <p>
                                    Being with Extronnect, you can create a culture of continuous
                                    learning and development within your organization. Our engaging
                                    and interactive training programs are designed to inspire, motivate,
                                    and empower your employees, fostering a high-performance work
                                    environment where individuals can thrive and grow. Our
                                    experienced trainers work closely with your team members to
                                    identify their training needs and develop customized training plans
                                    that address their specific challenges and opportunities.
                                </p>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </section>
            <Footer />
        </>
    );
}

export default TrainingDevelopment;
