import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import ScrollToTop from "../../ScrollToTop";
import Footer from "../../Footer";

function MotionGraphics() {
    return (
        <>
            <Helmet>
                <title>Motion Graphics</title>
            </Helmet>
            <ScrollToTop />

            <section className="serviceDetail">
                <h2 className="wow fadeInUp">Motion Graphics</h2>
                <Container>
                    <Row className="align-items-center">
                        <Col lg="12" md="12" sm="12">
                            <div className="wow fadeInUp">
                                <img
                                    src="images/servicesVectors/MotionGraphics.png"
                                    alt="Motion Graphics"
                                    className="mainimg"
                                />
                            </div>
                            <p className="wow fadeInUp">
                                In the realm of dynamic and interactive content, your proficiency
                                in motion graphics adds a layer of sophistication to visual
                                communication. Transforming static designs into dynamic,
                                animated experiences, you breathe life into graphics, making them
                                not just visually appealing but also interactive and memorable.
                                Your motion graphic expertise extends beyond aesthetic
                                considerations, incorporating strategic storytelling elements that
                                align with the broader brand narrative.
                            </p>

                        </Col>

                    </Row>
                </Container>
            </section>
            <Footer />

        </>
    );
}

export default MotionGraphics;
