import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import ScrollToTop from "../../ScrollToTop";
import { FaCircle } from "react-icons/fa";
import Footer from "../../Footer";

function OutboundSales() {
    return (
        <>
            <Helmet>
                <title>Outbound Sales</title>
            </Helmet>
            <ScrollToTop />

            <section className="serviceDetail">
                <h2 className="wow fadeInUp">Outbound Sales</h2>
                <Container>
                    <Row className="align-items-center">
                        <Col lg="12" md="12" sm="12">
                            <div className="wow fadeInUp">
                                <img
                                    src="images/servicesVectors/OutboundSales.png"
                                    alt="Outbound Sales"
                                    className="mainimg"
                                />
                            </div>
                            <div className="wow fadeInUp">

                            <h3 >
                                Unlocking Opportunities through Personalized Outreach
                            </h3>
                            <p >
                                At Extronnect, we redefine outbound sales as more than just making
                                calls—it's about forging meaningful connections and driving tangible
                                results for your business. Our outbound sales go beyond traditional
                                approaches, leveraging advanced techniques and personalized
                                strategies to engage prospects on a deeper level.
                            </p>
                            <p >
                                Our team of skilled sales is dedicated to understanding your target
                                audience, their needs, and pain points, allowing us to tailor our
                                approach for maximum impact. Whether it's generating leads,
                                scheduling appointments, or nurturing relationships, we ensure
                                every interaction is meaningful and drives toward your sales objectives.
                            </p>
                            <h3>With Extronnect's outbound sales solutions, You can expect:</h3>
                            <p >
                            <FaCircle />    Personalized outreach strategies tailored to your audience.
                            </p>
                            <p >
                            <FaCircle />   Expertly trained callers skilled in rapport-building and objection
                                handling.
                            </p>
                            <p >
                            <FaCircle />    Data-driven insights to optimize campaign performance.
                            </p>
                            <p >
                            <FaCircle />    Seamless integration with your existing sales processes.
                            </p>
                            <p >
                            <FaCircle />    Measurable results and ongoing performance tracking.
                            </p>
                            <p>
                                Partner with Extronnect and unlock the power of personalized
                                telemarketing to drive growth and success for your business.
                            </p>
                           
                            </div>

                        </Col>

                    </Row>
                </Container>
            </section>
            <Footer />

        </>
    );
}

export default OutboundSales;
