import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import ScrollToTop from "../ScrollToTop";
import { Link } from "react-router-dom";
import Footer from "../Footer";

function CreativeServices() {
  return (
    <>
    <div className="business">
      <Helmet>
        <title>Creative Services | Unleashing Design Excellence  </title>
      </Helmet>
      <ScrollToTop />

      <section className="homesec3" id="About">
        <h2 className=" wow fadeInUp">Creative Services</h2>
        <Container>
          <Row className="align-items-center">
            <Col lg="6" md="6" sm="12">
              <div className="sec3imgdiv wow fadeInLeft" >
                <img
                  src="images/vectors/creative.png"
                  alt="creative"
                  className="mainimg"
                />
              </div>
            </Col>
            <Col lg="6" md="6" sm="12">
              <div className="wow fadeInUp" >
                <h3>
                  With Extronnect your brand's visual identity with Extronnect's
                  creative services, encompassing graphic design and motion
                  graphics. Our team of talented designers and animators
                  specialises in creating eye-catching visuals that capture the
                  essence of your brand and resonate with your target audience.
                  Whether it's designing compelling marketing materials,
                  creating engaging social media content, or producing
                  captivating video animations, Extronnect's creative services
                  can help you stand out in a crowded marketplace and leave a
                  lasting impact with the help of our team, together we will
                  craft compelling visuals that resonate with your audience and
                  convey your brand's message effectively.
                </h3>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="serviceSec serviceb">
        <Container>
          <h2 className="wow fadeInUp" >Services Offered</h2>

          <Row>
            <Col md="8" lg="8" className="wow fadeInUp" >
              <Link to="/PublicationsAdvertisements">

                <h3>Publications and Advertisements (Graphic Design):</h3>
                <p>
                  Your graphic design prowess extends to crafting compelling
                  publications and advertisements that transcend mere visuals.
                  With an acute understanding of the target audience and brand
                  messaging, you meticulously design visuals that not only catch
                  the eye but also convey a narrative. From attention-grabbing
                  social media posts to print-ready materials, your designs
                  seamlessly blend creativity with purpose.
                </p>
              </Link>

            </Col>
            <Col md="4" lg="4">
              <div className="imgDiv wow fadeInRight" >
                <Link to="/PublicationsAdvertisements">
                  <img
                    src="images/services/op1/part5/GraphicDesign.png"
                    alt="Graphic Design"
                  />
                </Link>

              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="serviceSec differ">
        <Container>
          <Row>
            <Col md="4" lg="4">
              <div className="imgDiv wow fadeInLeft" >
                <Link to="/VisualIdentity">

                  <img
                    src="images/services/op1/part5/visualidentity.png"
                    alt="visual identity"
                  />
                </Link>

              </div>
            </Col>
            <Col md="8" lg="8" className="wow fadeInUp" >
              <Link to="/VisualIdentity">

                <h3>Visual Identity:</h3>
                <p>
                  At the heart of brand communication lies the visual identity,
                  and here is where your expertise truly shines. You intricately
                  weave together colors, typography, and imagery to construct a
                  distinctive visual language for your clients. Whether it's
                  creating or revamping logos, establishing consistent design
                  elements, or conceptualizing brand guidelines, your focus is on
                  forging a visual identity that resonates with the essence of
                  each brand you touch.
                </p>
              </Link>

            </Col>
          </Row>
        </Container>
      </section>

      <section className="serviceSec">
        <Container>
          <Row>
            <Col md="8" lg="8" className="wow fadeInUp">
              <Link to="/EditingVideos">

                <h3>Editing Videos:</h3>
                <p>
                  Video content dominates the social media landscape, and your
                  adeptness at video editing elevates storytelling to new heights.
                  From the initial conceptualization to the final cut, your
                  meticulous approach ensures seamless, engaging, and impactful
                  videos. Whether it's a short promotional clip or a longer-form
                  narrative, your editing skills bring a dynamic edge to the
                  visual narratives, captivating audiences and leaving a lasting
                  impression.
                </p>
              </Link>

            </Col>
            <Col md="4" lg="4">

              <div className="imgDiv wow fadeInRight">
                <Link to="/EditingVideos">

                  <img
                    src="images/services/op1/part5/EditingVideos.png"
                    alt="Editing Videos"
                  />
                </Link>

              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="serviceSec differ">
        <Container>
          <Row>
            <Col md="4" lg="4">
              <div className="imgDiv wow fadeInLeft">
                <Link to="/MotionGraphics">

                  <img
                    src="images/services/op1/part5/motiongraphics.png"
                    alt="motion graphics"
                  />
                </Link>

              </div>
            </Col>
            <Col md="8" lg="8" className="wow fadeInUp">
              <Link to="/MotionGraphics">

                <h3>Motion Graphics:</h3>
                <p>
                  In the realm of dynamic and interactive content, your
                  proficiency in motion graphics adds a layer of sophistication to
                  visual communication. Transforming static designs into dynamic,
                  animated experiences, you breathe life into graphics, making
                  them not just visually appealing but also interactive and
                  memorable. Your motion graphic expertise extends beyond
                  aesthetic considerations, incorporating strategic storytelling
                  elements that align with the broader brand narrative.
                </p>
              </Link>

            </Col>
          </Row>
        </Container>
      </section>
    </div>
    <Footer />
    </>
  );
}

export default CreativeServices;
