import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import ScrollToTop from "../../ScrollToTop";
import { FaCircle } from "react-icons/fa";
import Footer from "../../Footer";

function RealTimeAnalysts() {
    return (
        <>
            <Helmet>
                <title>Real-Time Analysts (RTA)</title>
            </Helmet>
            <ScrollToTop />

            <section className="serviceDetail">
                <h2 className="wow fadeInUp">Real-Time Analysts (RTA)</h2>
                <Container>
                    <Row className="align-items-center">
                        <Col lg="12" md="12" sm="12">
                            <div className="wow fadeInUp">
                                <img
                                    src="images/servicesVectors/Real-TimeAnalysts(RTA).png"
                                    alt="Real-Time Analysts (RTA)"
                                    className="mainimg"
                                />
                            </div>
                            <div className="wow fadeInUp">

                                <p >
                                    Unlock the Power of Data with Our Expert Analysts
                                </p>
                                <p>
                                    Real-time analysts at Extronnect are not just data crunchers—they
                                    are the driving force behind transformative insights that propel
                                    your business forward. With precision and expertise, our analysts
                                    delve into the depths of your customer support data, uncovering
                                    hidden patterns and invaluable opportunities for optimization.
                                </p>
                                <p>
                                    Our seasoned team of analysts leverages cutting-edge analytics tools
                                    and methodologies to sift through vast volumes of data in real-time.
                                    From customer interactions to performance metrics, we leave no
                                    stone unturned in our quest for actionable insights that drive
                                    informed decision-making.
                                </p>
                                <p>
                                    By partnering with Extronnect for your real-time analytics needs,
                                    you gain access to a wealth of insights that empower you to make
                                    strategic decisions with confidence. Whether it's identifying
                                    emerging trends, detecting potential issues, or capitalizing on
                                    untapped opportunities, our RTA experts are your trusted allies in
                                    the quest for continuous improvement.
                                </p>
                                <p>
                                    We understand that every business is unique, which is why our
                                    analytics solutions are tailored to your specific objectives and
                                    challenges. From custom dashboards to real-time alerts, we provide
                                    personalized analytics solutions that align seamlessly with your
                                    business goals and priorities.
                                </p>
                                <h3>With Extronnect’s Real-Time Analysts (RTA) service, You can expect:</h3>
                                <p>
                                    <FaCircle />    Continuous monitoring and analysis of key performance indicators
                                    (KPIs) to track progress and identify areas for improvement
                                </p>
                                <p>
                                    <FaCircle />    Customized dashboards and reports that provide actionable
                                    insights tailored to your specific business needs
                                </p>
                                <p>
                                    <FaCircle />   Predictive analytics capabilities that help forecast future trends
                                    and anticipate customer behavior
                                </p>
                                <p>
                                    <FaCircle />   Proactive alerts and notifications to alert you to potential issues or
                                    opportunities in real-time
                                </p>
                                <p>
                                    <FaCircle />    Expert guidance and support from our team of analysts to help you
                                    interpret and apply insights to drive business outcomes
                                </p>
                                <p>
                                    Experience the difference with Extronnect by your side, you're not just
                                    getting analytics—you're gaining a strategic advantage.
                                    Our commitment to delivering exceptional customer support extends
                                    to every aspect of our service, ensuring that you receive the insights
                                    and support you need to thrive in today's competitive landscape.
                                </p>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </section>
            <Footer />

        </>
    );
}

export default RealTimeAnalysts;
