import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import ScrollToTop from "../ScrollToTop";
import { Link } from "react-router-dom";
import Footer from "../Footer";

function BusinessAdministration() {
  return (
    <>
    <div className="business">
         <Helmet>
        <title>Business Administration | Streamlined Operations & Productivity Boost  </title>
      </Helmet>
      <ScrollToTop /> 

      <section className="homesec3 ">
        <h2 className=" wow fadeInUp">Business Administration Services</h2>
        <Container>
          <Row className="align-items-center">
            <Col lg="6" md="6" sm="12">
              <div className="sec3imgdiv wow fadeInLeft" data-wow-duration="1s">
                <img
                  src="images/vectors/BusinessAdministrationService.png"
                  alt="Business Administration Service"
                  className="mainimg"
                />
              </div>
            </Col>
            <Col lg="6" md="6" sm="12">
              <div className="wow fadeInUp" data-wow-duration="1s">
                <h3>
                  Enhance your business operations with our comprehensive
                  management solutions. From back-office support to operational
                  oversight, we optimise processes to enhance efficiency and
                  productivity. Trust us to be your partner in navigating the
                  intricacies of operations management and driving sustainable
                  growth for your business.
                </h3>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="homesec2">
        <Container>
          <h2>Services Offered</h2>
          <Row>
            <Col lg="4" md="4" sm="12">
              <div>
                <div className=" wow fadeInUp" data-wow-duration="1s">
                <Link to="/ExecutiveAssistance">

                  <div className="hoverdetail">
                    <img
                      src="images/services/op1/part4/ExecutiveAssistance.png"
                      alt="Executive Assistance"
                    />

                    <h3>
                      Executive <br /> Assistance
                    </h3>
                  </div>
                  </Link>
                </div>
              </div>
            </Col>

            <Col lg="4" md="4" sm="12">
              <div>
                <div className=" wow fadeInUp" data-wow-duration="1s">
                <Link to="/OperationsManagement">

                  <div className="hoverdetail">
                    <img
                      src="images/services/op1/part2/Operationsmanagement.png"
                      alt="Operations management"
                    />

                    <h3>
                      Operation <br />
                      Management
                    </h3>
                  </div>
                  </Link>

                </div>
              </div>
            </Col>

            <Col lg="4" md="4" sm="12">
              <div>
                <div className=" wow fadeInUp" data-wow-duration="1s">
                <Link to="/CustomerSuccesManagement">
                  <div className="hoverdetail">
                    <img
                      src="images/services/op1/part4/CustomerSuccessManagement.png"
                      alt="Customer Success Management"
                    />

                    <h3>
                      Customer Success
                      <br />
                      Management
                    </h3>
                  </div>
                  </Link>

                </div>
              </div>
            </Col>

            <Col lg="4" md="4" sm="12">
              <div>
                <div className=" wow fadeInUp" data-wow-duration="1s">
                <Link to="/PayrollManagement">

                  <div className="hoverdetail">
                    <img
                      src="images/services/op1/part4/PayrollManagement.png"
                      alt="Payroll Management"
                    />

                    <h3>
                      Payroll <br />
                      Management
                    </h3>
                  </div>
                  </Link>

                </div>
              </div>
            </Col>

            <Col lg="4" md="4" sm="12">
              <div>
                <div className=" wow fadeInUp" data-wow-duration="1s">
                <Link to="/TrainingDevelopment">

                  <div className="hoverdetail">
                    <img
                      src="images/services/op1/part4/Training&Development.png"
                      alt="Training & Development"
                    />

                    <h3>
                      Training &<br /> Development
                    </h3>
                  </div>
                  </Link>

                </div>
              </div>
            </Col>
            <Col lg="4" md="4" sm="12">
              <div>
                <div className=" wow fadeInUp" data-wow-duration="1s">
                <Link to="/QualityControl">

                  <div className="hoverdetail">
                    <img
                      src="images/services/op1/part4/QualityControl.png"
                      alt="Quality Control"
                    />

                    <h3>
                      Quality
                      <br />
                      Control
                    </h3>
                  </div>
                  </Link>

                </div>
              </div>
            </Col>
          </Row>
          <p>
            Our services are designed to provide comprehensive support including
            but not limited business functions. From administrative tasks such
            as data entry and document management to more specialized roles like
            customer service and project coordination, Our hires are equipped to
            handle diverse responsibilities. By outsourcing these tasks to
            Extronnect, With extensive experience and expertise, you can free up
            valuable time and resources, we will handle diverse requirements,
            providing efficient and reliable support to optimize your workflow.
          </p>
        </Container>
      </section>
    </div>

    <Footer />
    </>
  );
}

export default BusinessAdministration;
