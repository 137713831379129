import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import ScrollToTop from "../../ScrollToTop";
import Footer from "../../Footer";

function SocialMediaMarketing() {
    return (
        <>
            <Helmet>
                <title>Social Media Marketing & Moderation</title>
            </Helmet>
            <ScrollToTop />

            <section className="serviceDetail">
                <h2 className="wow fadeInUp">Social Media Marketing <br /> & Moderation</h2>
                <Container>
                    <Row className="align-items-center">
                        <Col lg="12" md="12" sm="12">
                            <div className="wow fadeInUp">
                                <img
                                    src="images/servicesVectors/SocialMediaMarketing.png"
                                    alt="SocialMediaMarketing"
                                    className="mainimg"
                                />
                            </div>
                            <div className="wow fadeInUp">

                            <p>
                                Building Relationships and Driving Engagement
                            </p>
                            <p>
                         
                                Social media has become a powerful tool for businesses to connect
                                with their audience, build brand awareness, and drive engagement.
                                At Extronnect, we offer comprehensive Social Media Marketing &
                                Moderation services designed to help businesses leverage the full
                                potential of social media to achieve their marketing goals.
                            </p>
                            <p>
                           Our Social Media Marketing & Moderation services encompass a wide
                                range of activities, including:
                            </p>
                            <p>
                            <h5>Social Media Strategy Development:  </h5> We work with you to develop
                                a comprehensive social media strategy that aligns with your business
                                objectives, target audience, and industry landscape.
                            </p>
                            <p>
                            <h5> Content Creation and Curation:  </h5> Our team of experienced content
                                creators develops engaging content tailored to each social media
                                platform, ensuring consistency and relevance across all channels.
                            </p>
                            <p>
                            <h5> Community Management:  </h5> We engage with your audience, respond
                                to comments and messages, and foster meaningful conversations
                                to build brand loyalty and trust.
                            </p>
                            <p>
                            <h5>  Social Media Advertising:  </h5> We create and manage targeted social
                                media advertising campaigns to reach your ideal audience and drive
                                conversions.
                            </p>
                            <p>
                            <h5> Analytics and Reporting: </h5> We track key metrics and analyze
                                performance data to measure the effectiveness of your social media
                                efforts and inform future strategies.
                            </p>
                            <p>
                                Let Extronnect handle your social media presence, build relationships
                                with your audience, and drive engagement and conversions through
                                strategic social media Marketing & Moderation initiatives.
                            </p>
                           
                            </div>

                        </Col>

                    </Row>
                </Container>
            </section>
            <Footer />
        </>
    );
}

export default SocialMediaMarketing;
