import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import ScrollToTop from "../ScrollToTop";
import { Link } from "react-router-dom";
import Footer from "../Footer";

function SalesSolutions() {
  return (
    <>
      <div className="business">
        <Helmet>
          <title>Automated Sales | Achieving Business Objectives Strategically</title>
        </Helmet>
        <ScrollToTop />

        <section className="homesec3 ">
          <h2>Automated Sales</h2>
          <Container>
            <Row className="align-items-center">
              <Col lg="6" md="6" sm="12">
                <div className="sec3imgdiv wow fadeInLeft" data-wow-duration="1s">
                  <img
                    src="images/vectors/salessolutions.png"
                    alt="sales solutions"
                    className="mainimg"
                  />
                </div>
              </Col>
              <Col lg="6" md="6" sm="12">
                <div className="wow fadeInUp" data-wow-duration="1s">
                  <h3>
                    Enhance your business strategies with our tailored solutions
                    designed to meet the unique demands of various sectors,
                    including real estate, solar, insurance, automotive, and more.
                    From adeptly handling acquisitions to orchestrating seamless
                    dispositions, we skillfully navigate the intricacies of
                    negotiations, ensuring optimal outcomes for our esteemed
                    clientele. Partner with us as your strategic ally and unlock
                    unprecedented success across a spectrum of industries.
                  </h3>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="homesec2">
          <Container>
            <h2>Services Offered</h2>
            <Row>
              <Col lg="4" md="4" sm="12">
                <div>
                  <div className=" wow fadeInUp" data-wow-duration="1s">
                    <Link to="/OutboundSales">
                      <div className="hoverdetail">
                        <img
                          src="images/services/op1/part4/Telemarketing.png"
                          alt="Tele marketing"
                        />

                        <h3>
                          Outbound Sales
                        </h3>
                      </div>
                    </Link>

                  </div>
                </div>
              </Col>

              <Col lg="4" md="4" sm="12">
                <div>
                  <div className=" wow fadeInUp" data-wow-duration="1s">
                    <Link to="/AcquisitionClosers">
                      <div className="hoverdetail">
                        <img
                          src="images/services/op1/AcquisitionsClosing.png"
                          alt="Acquisitions Closing"
                        />

                        <h3>
                          Acquisition Closers

                        </h3>
                      </div>
                    </Link>
                  </div>
                </div>
              </Col>

              <Col lg="4" md="4" sm="12">
                <div>
                  <div className=" wow fadeInUp" data-wow-duration="1s">
                    <Link to="/CustomerSuccesManagementS">

                      <div className="hoverdetail">
                        <img
                          src="images/services/op1/part4/DispositionsManagement.png"
                          alt="Dispositions Management"
                        />

                        <h3>
                          Customer Success <br />Management

                        </h3>
                      </div>
                    </Link>
                  </div>
                </div>
              </Col>

              <Col lg="4" md="4" sm="12">
                <div>
                  <div className=" wow fadeInUp" data-wow-duration="1s">
                    <Link to="/DataManagement">

                      <div className="hoverdetail">
                        <img
                          src="images/services/op1/part4/DataManagement.png"
                          alt="Data Management"
                        />

                        <h3>
                          Data Management

                        </h3>
                      </div>
                    </Link>
                  </div>
                </div>
              </Col>

              <Col lg="4" md="4" sm="12">
                <div>
                  <div className=" wow fadeInUp" data-wow-duration="1s">
                    <Link to="/TrainingDevelopmentS">
                      <div className="hoverdetail">
                        <img
                          src="images/services/op1/part4/Training&Development.png"
                          alt="Training & Development"
                        />

                        <h3>
                          Training & <br />Development
                        </h3>
                      </div>
                    </Link>
                  </div>
                </div>
              </Col>
              <Col lg="4" md="4" sm="12">
                <div>
                  <div className=" wow fadeInUp" data-wow-duration="1s">
                    <Link to="/QualityControlS">
                      <div className="hoverdetail">
                        <img
                          src="images/services/op1/part4/QualityControl.png"
                          alt="Quality Control"
                        />

                        <h3>
                          Quality
                          <br />
                          Control
                        </h3>
                      </div>
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
            <p>
              Extronnect presents bespoke outsourcing solutions meticulously crafted to cater to the specialized requirements of enterprises within a multitude of industries. Our adaptable and scalable services encompass a diverse array of functions, spanning from precision lead generation and seamless appointment setting to insightful data analysis and beyond. By forging a partnership with Extronnect, businesses can streamline their operations, optimize resource allocation, and gain a decisive competitive edge within their respective sectors.
            </p>
          </Container>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default SalesSolutions;
