import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import ScrollToTop from "../../ScrollToTop";
import Footer from "../../Footer";

function PublicationsAdvertisements() {
    return (
        <>
            <Helmet>
                <title>Publications and Advertisements (Graphic Design)</title>
            </Helmet>
            <ScrollToTop />

            <section className="serviceDetail">
                <h2 className="wow fadeInUp">Publications and Advertisements <br />(Graphic Design)</h2>
                <Container>
                    <Row className="align-items-center">
                        <Col lg="12" md="12" sm="12">
                            <div className="wow fadeInUp">
                                <img
                                    src="images/servicesVectors/PublicationsAdvertisements.png"
                                    alt="Publications and Advertisements (Graphic Design)"
                                    className="mainimg"
                                />
                            </div>
                            <p className="wow fadeInUp">
                                Your graphic design prowess extends to crafting compelling publications and advertisements that transcend mere visuals. With an acute understanding of the target audience and brand messaging, you meticulously design visuals that not only catch the eye but also convey a narrative. From attention-grabbing social media posts to print-ready materials, your designs seamlessly blend creativity with purpose.
                            </p>

                        </Col>

                    </Row>
                </Container>
            </section>
            <Footer />

        </>
    );
}

export default PublicationsAdvertisements;
