import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import ScrollToTop from "../../ScrollToTop";
import Footer from "../../Footer";
import { FaCircle } from "react-icons/fa";

function CustomerSupportRepresentatives() {
    return (
        <>
            <Helmet>
                <title>Customer Support Representatives</title>
            </Helmet>
            <ScrollToTop />

            <section className="serviceDetail">
                <h2 className="wow fadeInUp">Customer Support <br />Representatives</h2>
                <Container>
                    <Row className="align-items-center">
                        <Col lg="12" md="12" sm="12">
                            <div className="wow fadeInUp">
                                <img
                                    src="images/servicesVectors/CustomerSupportRepresentatives.png"
                                    alt="Customer Support Representatives"
                                    className="mainimg"
                                />
                            </div>
                            <div className="wow fadeInUp">

                                <p>
                                    Deliver Exceptional Service with Expert Support Representatives
                                </p>
                                <p>
                                    At Extronnect, our Customer Support Representatives are the frontline champions dedicated to delivering exceptional service and support to your valued customers. With a deep understanding of your products or services, our skilled representatives provide prompt and personalized assistance, resolving inquiries, addressing concerns, and ensuring customer satisfaction at every interaction.
                                </p>
                                <h3>
                                    With Extronnect's Customer Support Representatives, You can expect:
                                </h3>
                                <p>
                                <FaCircle />     Prompt and personalized assistance for customer inquiries and concerns.    
                                </p>
                                <p>
                                <FaCircle />   Extensive product knowledge to provide accurate information and recommendations.
                                </p>

                                <p>
                                <FaCircle />   Multi-channel support including phone, email, and live chat for maximum accessibility.
                                </p>
                                <p>
                                <FaCircle />  Proactive problem-solving to anticipate and address customer needs before they arise.
                                </p>
                                <p>
                                <FaCircle />  Continuous training and development to ensure our representatives are equipped with the latest skills and knowledge.
                                </p>
                                <p>
                                    Partner with Extronnect to elevate your experience and build long-lasting relationships with your customers.
                                </p>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </section>
            <Footer />

        </>
    );
}

export default CustomerSupportRepresentatives;
