import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import ScrollToTop from "../../ScrollToTop";
import Footer from "../../Footer";

function ContentCreation() {
  return (
    <>
      <Helmet>
        <title>Content Creation</title>
      </Helmet>
      <ScrollToTop />

      <section className="serviceDetail">
        <h2 className="wow fadeInUp">Content Creation</h2>
        <Container>
          <Row className="align-items-center">
            <Col lg="12" md="12" sm="12">
              <div className="wow fadeInUp">
                <img
                  src="images/servicesVectors/ContentCreation.png"
                  alt="Content Creation"
                  className="mainimg"
                />
              </div>
              <div className="wow fadeInUp">
                <p>Engaging Your Audience with Compelling Content</p>
                <p>
                  Content has always been the king. At Extronnect, we understand
                  the power of great content in driving engagement, building
                  brand awareness, and driving conversions. Our Content Creators
                  are dedicated to helping businesses create high-quality,
                  relevant, and engaging content that resonates with their
                  target audience and drives action.
                </p>
                <p>
                  Our Content Creation process begins with a deep understanding
                  of your business objectives, target audience, and industry
                  landscape. We work closely with you to develop a content
                  strategy that aligns with your goals and resonates with your
                  audience. From there, our team of experienced content creators
                  and copywriters collaborates to produce a wide range of
                  content assets, including blog posts, articles, videos,
                  infographics, and more.
                </p>
                <h3>Key elements of our Content Creation service include:</h3>
                <p>
                  <h5>Content Strategy Development: </h5> We work with you to
                  define your content goals, target audience, messaging, and
                  distribution channels, ensuring alignment with your overall
                  marketing strategy.
                </p>
                <p>
                  <h5> Content Ideation and Creation: </h5> Our team generates
                  creative ideas and develops engaging content that educates,
                  entertains, and inspires your audience, driving brand
                  engagement and loyalty.
                </p>
                <p>
                  <h5> Content Optimization: </h5> We optimize your content for
                  search engines (SEO) to improve visibility and drive organic
                  traffic to your website, helping you attract and retain your
                  target audience.
                </p>
                <p>
                  <h5> Content Distribution: </h5> We develop customized content
                  calendars and editorial schedules to ensure consistency and
                  relevance across all content touchpoints, maximizing the
                  impact of your content marketing efforts.
                </p>
                <p>
                  With Extronnect you will create compelling content that
                  captivates your audience, builds brand loyalty, and drives
                  results.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
}

export default ContentCreation;
