import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import ScrollToTop from "./ScrollToTop";
import Footer from "./Footer";

function About() {
  return (
    <>
    <div className="business aboutt">
         <Helmet>
        <title>Extronnect | About Us - Unlocking Business Potential  </title>
      </Helmet>
      <ScrollToTop /> 

      <section className="homesec3">
        <h2>About Us</h2>
        <Container>
          <Row >
          <Col lg="6" md="6" sm="12">
              <div className="sec3imgdiv wow fadeInRight" data-wow-duration="1s">
                <img
                  src="images/vectors/aboutus/OurJourney.png"
                  alt="Our Journey"
                  className="mainimg"
                />
              </div>
            </Col>
            <Col lg="6" md="6" sm="12">
              <div className="wow fadeInUp" data-wow-duration="1s">
                <h5>Our Journey</h5>
                <p>
                  Founded with a vision to redefine excellence in service
                  provision, Extronnect has rapidly evolved into a trusted
                  partner for businesses seeking unparalleled support and
                  innovation. Over the years, we have cultivated enduring
                  relationships with a select group of clients, establishing
                  ourselves as a leader in the industry.
                </p>
              </div>
            </Col>
            
          </Row>
        </Container>
      </section>
      <section className="homesec3">
      {/* <div className="borderDiv"></div> */}

        <Container>
          <Row >
    
            <Col lg="6" md="6" sm="12">
              <div className="wow fadeInUp" data-wow-duration="1s">
                <h5>Our Expertise</h5>
                <p>
                  At Extronnect, we pride ourselves on our ability to deliver
                  tailor-made solutions that drive tangible results. With a
                  dedicated team of seasoned professionals, we bring a wealth of
                  knowledge and experience to every project. From crafting
                  compelling marketing campaigns to optimising operational
                  workflows, we empower businesses to thrive in today's dynamic
                  market landscape.
                </p>
              </div>
            </Col>
            <Col lg="6" md="6" sm="12">
              <div className="sec3imgdiv wow fadeInLRight" data-wow-duration="1s">
                <img
                  src="images/vectors/aboutus/OurExpertise.png"
                  alt="Our Expertise"
                  className="mainimg"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="homesec3 differAbout">
      {/* <div className="borderDiv"></div> */}

        <Container>
          <Row>
          
            <Col lg="6" md="6" sm="12">
              <div className="sec3imgdiv wow fadeInLeft" data-wow-duration="1s">
                <img
                  src="images/vectors/aboutus/OurPromise.png"
                  alt="Our Promise"
                  className="mainimg"
                />
              </div>
            </Col>

            <Col lg="6" md="6" sm="12">
              <div className="wow fadeInUp" data-wow-duration="1s">
                <h5>Our Promise</h5>
                <p>
                  Our commitment to excellence extends beyond mere service
                  delivery; it's ingrained in everything we do. We are driven by
                  a passion for innovation, a dedication to quality, and an
                  unwavering focus on client satisfaction, along with the latest
                  AI-Powered technologies. With Extronnect by your side, you can
                  trust that your business is in capable hands, poised for
                  growth and success.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
     
    </div>
    <Footer />

    </>
  );
}

export default About;
